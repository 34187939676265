import React, { useEffect, useState } from "react"
import LoadingSpinner from "../LoadingSpinner"

function convertDate(dateString) {
  // Date 객체로 변환
  const date = new Date(dateString)

  // 연도, 월, 일 추출
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // 월은 0부터 시작하므로 1을 더함
  const day = String(date.getDate()).padStart(2, "0") // 일자도 2자리로 맞추기

  // "년-월-일" 형식으로 출력
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

const Post = ({ post: { title, pubDate, description, link } }) => {
  return (
    <li className="relative w-full p-2 mb-8 text-neutral-700 sm:p-0">
      <div className="absolute w-full rounded-lg opacity-30 -inset-1 bg-gradient-to-tl from-fuchsia-400 via-stone-400 to-purple-400 sm:blur-lg blur-md"></div>
      <div className="relative items-center justify-center w-full p-2 bg-white border border-white rounded-lg md:h-32">
        <p className="justify-between p-1 mb-1 font-bold sm:flex">
          <span className="">{title}</span>
          <small className="font-light"> {pubDate}</small>
        </p>
        <p className="p-1 text-xs font-medium md:text-sm text-neutral-500">
          {description}
          <a href={link} className="text-xs underline md:text-sm text-sky-700">
            블로그 글 보러가기{" "}
          </a>
        </p>
      </div>
    </li>
  )
}

function TistoryRSSFeed() {
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  useEffect(  () => {
      fetch("/.netlify/functions/fetchTistoryRSS")
      .then((response) => response.text())
      .then((str) => {

        const parser = new DOMParser()
        const xml = parser.parseFromString(str, "application/xml")
        const items = xml.querySelectorAll("item")
        const postsArray = Array.from(items).map((item) => ({
          title: item.querySelector("title").textContent,
          link: item.querySelector("link").textContent,
          description:
            item
              .querySelector("description")
              .textContent.replace(/<\/?[^>]+(>|$)/g, "")
              .replace(/&nbsp;/g, " ")
              .slice(0, 200) + "...",
          pubDate: convertDate(item.querySelector("pubDate").textContent),
        }))
        setPosts(postsArray)
      })
      .then(()=>{setIsLoading(false)})
      .catch((error) => console.error("Failed to fetch RSS feed:", error))

  }, [])


  return (
    <div className="justify-center w-full max-w-5xl m-auto sm:p-5 sm:px-20">
      <h3 className="px-4 mt-4 mb-6 text-neutral-700">Latest Blog Posts</h3>
      {isLoading?<div className="w-full my-4"><LoadingSpinner size="sm" color="purple" /></div>
      :
      <ul className="w-full sm:mx-6">
        {posts.map((post, index) => (
          <Post post={post} key={index} />
        ))}
      </ul>
}
    </div>
  )
}

export default TistoryRSSFeed
